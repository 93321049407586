const NAMES = {
    ENG: {
        sideBarAdmin:{
            dashboard:'Dashboard',
            websites:'Websites',
            creatives:'Creatives',
            automation:'Automation',
            logs:'Logs',
            subsData: "Subscribers"
        },
        
        dashboardContent:{
            title:"Dashboard",

        },

        websitesContent: {
            managewebsites: 'Manage Websites',
            newDomain: 'Add new domain',
            item_selected: 'items selected',
            bodytag:'body.js',
            downloadpushjs:'push.js',
            downloadswjs: 'sw.js',
            addWebsites: 'Add +',
            delete:'Delete',
            search:'Search',
            editEachWebsite:'Edit',
            deleteEachWebsite:'Delete',
            no_data_found: 'No data found by the query you\'ve made',
            no_data_found_website: 'No website found',
            delete_title: 'Want to delete this website?',

            // clicks data for website
            checkClickData: 'Clicks ☛',
            showWebsites:"Websites ❁",
            copy_to_clipboard:"Copy to Clipboard ❐",
            download:'Download ↓',


            table_headers: {
                w_id:"Website ID",
                favicon: 'Favicon',
                domain: 'Domain',
                verified: 'Verified',
                verificationChecked: 'V. Checked?',
                verify_now: 'Verify Now',
                
                total_subscribers: 'Subscribers',
                total_unsubscribed: 'Unsubscribed',
                total_articles: "Articles",
                creatives_created: "Creatives",
                bulk_schedule_now: 'Schedule Now',
                enable_schedule: 'Schedule On?',

                dispatched: 'Dispatched',
                dispatch_failed: 'Dispatch Failed',
                impressions: 'Impressions',
                clicks: 'Clicks',
                ctr: 'CTR',
                actions_tab:"Actions",
                run_daily:"Run Daily?",
                automate:"Automate?"

                // added: 'added',
                // type: 'type',
                // status: 'status',
                // websiteID: 'websiteID',
                // owned: 'Start service',
                // earnedNIS: '₪ earned',
                // earnedUSD: '$ earned ',
                // retainer: 'retainer',
                // customer: 'customer',
                // show_portal: 'Show Portal Users',
                // show_websites: 'Show Websites',
                // new_domain: 'Add New Domain',
                // import: 'Import From Spreadsheet',
                // export: 'Export From Spreadsheet',
                // reset: 'Reset',
                // websites: 'Websites',
                // first_name: 'First Name',
                // last_name: 'Last Name',
                // email: 'Email',
                // status: 'Status',
                // units: 'Units',
                // portal: 'Portal',
                // search_domain: 'Search By Domain',
                // search_portal: 'Search By Name Or Email'
            },
            
            AddNewDomain: {
                title: 'Add Domain',
                domain_name: 'Domain',
                favicon: 'Favicon',
                select_website_type: 'Select portal or website',
                cancel: 'Cancel',
                add: 'Add +'
            },
            

            pagination: {
                showing: '',
                rows: 'items',
                page: 'Page',
                of: 'of',
                out_of:'out of',
            },
        },

        creativesContent: {
            creatives: 'Creatives',
            creatives_clicks: 'Click Data',
            item_selected: 'items selected',
            addCreatives: 'Add +',
            checkClickData: 'Clicks ☛',
            checkCreativeDiv: "Creatives ♚",
            delete:'Delete',
            search:'Search',
            editEachCreative:'Edit',
            deleteEachCreative:'Delete',
            no_data_found: 'No data found by the query you\'ve made',
            no_clicks_data_found: 'No clicks data found by the query you\'ve made',
            delete_title: 'Want to delete this creative?',
            add_images: 'Add Images +',
            go_back: '← Go Back',
            cancel:'Cancel',
            save:'Save ✓',
            reset:'Reset ◉',
            upload:'Upload',
            upload_by_urls:'Enter Image Urls',
            upload_single_url:'Enter Image Url',
            select_files:'Select Image Files',
            select_single_file:'Select Image File',
            upload_by_urls_placeholder:'Comma separated values',
            upload_single_url_placeholder:'Image url',


            table_headers:{
                favicon: 'Favicon',
                domain: 'Domain',
                post_id: 'Post Id',
                title: 'Title',
                description: 'Description',
                image: 'Image',
                link: 'Link',
                target_websites: 'Target Websites',
                expected_subs: 'Targeting Subs',
    
                scheduled_date: 'Scheduled Date',
                schedule_on: 'Schedule On',
                status: 'Status',
                run_daily:"Run Daily?",
                automate: "Automate?",
                already_ran_today: "Ran Today?",
                scheduled: 'Scheduled',
                send_now:'Send Now',
                screen_name: 'Screen Name',
                u_id: 'Unique Id',
                screen_started: 'Screen Started',
                screen_ended: 'Screen Ended',
    
                created_at: 'Created At',
                updated_at: 'Updated At',
                deleted_at: 'Deleted At',
    
                dispatched: 'Dispatched',
                dispatch_failed: 'Dispatch Failed',
                impressions: 'Impressions',
                clicks: 'Clicks',
                actions: 'Actions'
            },
            clicks_table_headers:{
                favicon: 'Favicon',
                domain: 'Domain',
                title: 'Title',
                image: 'Image',
                link: 'Link',

                today: 'Today',
                yesterday: 'Yesterday',
                last_seven_days: 'Last 7D',
                last_thirty_days: 'Last 30D',
                this_month: 'This Month',
                last_month: 'Last Month',
                total_clicks: 'Total Clicks',
                updated:'Last Updated',
                u_id:'Tracking Id',
                w_id:'Website Id'
            },
            edit_image_headers:{
                file_name:'File Name',
                type:'Type',
                image:'Image',
                date:'Date',

                title: 'Title',
                description: 'Description',
                link: 'Link',                
                action:'Action',
            },
            creative_status:{
                all: 'All',
                active: 'Active',
                inactive: 'Inactive',
                running: 'Running',
                interrupt: 'Interrupt',
                completed: 'Completed',
            },

            AddOrEditCreative:{
                title: 'Add new creative',
                title_edit: 'Edit creative',
                btn:'Add +',
                btn_edit:'Save Creative',
                reset:'Reset ◉',
                target_websites:'Target Websites',
                status:'Status',
                run_daily:'Run Daily?',
                automate:'Automate?',
                schedule_date:'Schedule Date',
                schedule_on:'Schedule On',

                new_target_websites:"New target websties",
                new_status:"New Status",
                new_schedule_date:"New Schedule Date",
                new_schedule_time:"New Schedule Time",

                editCreativeInfo: 'Target Creative/Edit Information',
                creativeDomain: 'Domain',
                creativePostId: 'Post ID',
                creativeTitle:'Title',
                creativeLink:'Link',
                creativeFavicon:'Favicon',    
                creativeImage:'Image Link',   
                creativeImageByUpload: 'Upload Image',
                creativeDescription:'Description',         
                creativeStatus:'Status',  
                creativeTargetWebsites:'Target Websites (comma separated)',
                creativeByUrl: "Create By Url",          
            }

        },



        automationContent: {
            automation: 'Automation',
            unspecified: 'Uncategorized Creatives',
            funnel_tier: 'Funnel Tiers',
            showFunnelTier:"Funnels ⚃",
            showUnspecifiedDiv:"Unassigned ◎",

            addAutomation: 'Add +',
            addFunnelTier: "Add +",  
            delete: 'Delete',
            delete_title: 'Want to remove this creative from automation?',
            delete_from_tier_title: "Want to remove this creative from this tier?",
            no_data_found: 'No automation creatives found for target tier',
            no_funnel_tier_found: 'No funnel tier found',
            title_edit: "Edit Automation Timing",
            btn_save:"Save",
            automation_time_label:"Automation Run Time",


            // funnel tier
            delete_title_funnel_tier: 'Want to delete funnel tier : ',
            delete_multiple_funnel_tier: "Want to delete selected funnel tiers?",
            delete_multiple_us: "Want to remove selected creatives?",
            no_unspecified_creative_found: "No unassigned creatives found",

            table_headers:{
                subheading: "Automation Creatives",
                favicon: 'Favicon',
                domain: 'Domain',
                title: 'Title',
                description: 'Description',
                image: 'Image',
                link: 'Link',
                target_websites: 'Target Websites',
                automation_time: 'At',
                actions: 'Actions'
            },
            
            tier_table_headers:{
                subheading: "Funnel Tiers",
                tier_name : 'Funnel Name',
                target_websites : 'Websites',
                target_websites_len : 'Total Websites',
                target_automation_len : 'Automation Creatives',
                created_at : 'Created',
                updated_at : 'Updated',
                actions: 'Actions'
            },
            

            funnel_tier_add_edit:{
                title_add:'Add New Funnel Tier',
                title_edit:'Edit Funnel Tier',
                editFunnelTier:'Edit Target Funnel',
                addFunnelTier:'Add New Funnel',
                tier_name:"New Funnel Tier Name",
                tier_name_placeholder:'Enter Tier Name',
                
                target_websites:"Target Domains",
                edit_target_websites:"Target Domains",
                target_websites_placeholder:'Select Domain Names',
                select_websites_placeholder:'Select Multiple Websites',
                
                target_automation:"Target Automation Creatives",
                edit_target_automation:"Target Automation Creatives",
                target_automation_placeholder:'Select Creatives',

                create_tier_btn:'Create',
                update_tier_btn:'Update',
                cancel_tier_btn:'Cancel',

            },

            actions:{
                save:'Save'
            }
        },

        

        logs: {
            title: 'Logs',
            impersonate: 'Running',
            customer_create: 'Create',
            website_active: 'Add +',
            website_others: 'Delete',
            on_air: 'Active',
            transfer: 'Inactive',
            delete_transfer: 'Complete',
            move_transfer: 'Interrupt',
            headers: {
                date: 'Date',
                type: 'Type',
                responsible: 'Responsible',
                details: 'Details'
            },

            types: {
                website_status: 'Website status',
                transfer: 'New transfer',
                transfer_del: "Delete transfer",
                new_customer: "New customer",
                onair: 'On air',
                delete_onair: 'Delete On air',
                transfer_move: 'Move transfer',
                impersonate: 'Impersonate customer'
            },
            yesterday: 'Yesterday',
            today: 'Today'
        },

        subscribers:{
            title: 'Subscribers',
            no_data_found:"No data found",
            search_data:"Search",
            start_date:"From",
            end_date:"To",
            headers: {
                domain: "Domain",
                w_id: "Website ID",
                favicon: "Favicon",
                subs_title:"Subs Data",
                unsubs_title:"Unsubs Data",
                today: "Today",
                yesterday: "Yesterday",
                last_seven_days: "Last 7 Days",
                last_thirty_days: "Last 30 Days",
                this_month: "This Month",
                last_month: "Last Month",
                subs_by_query:"Subscribed"
            },
        }
    }
}

export default NAMES;