
import { createSlice } from '@reduxjs/toolkit'

const userSlice = createSlice({
    name: 'user',
    initialState: {
            token: null,
            isAdmin: false,
            isModerator: false,
            lang: 'ENG',
            email: '',
    },
    reducers: {
        setUser(state, action) {
            state.email = action.payload
        },
        setIsAdmin(state, action) {
            state.isAdmin = action.payload
        },
        setIsModerator(state, action) {
            state.isModerator = action.payload
        },
        setToken(state, action) {
            state.token = action.payload
        },
        setEmail(state, action) {
            state.email = action.payload
        },
    }
})

export const userActions = userSlice.actions
export default userSlice