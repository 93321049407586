import main_classes from '../Main/Main.module.scss'
import classes from './EditPrompt.module.scss'

import { useEffect, useState, useRef } from 'react';

import NavBar from "../../components/NavBar/NavBar";
import { GooSpinner } from "react-spinners-kit";

import useWindowSize from '../../assets/hooks/useWindowSize.js'
import {TimeOutHandler, decryptData} from '../../utils/helper_functions'



const EditPrompt = () => {
    let {width} = useWindowSize()
    const elementRef = useRef(null);

    const API_URL = process.env.REACT_APP_API_URL
    const aes_salt_key = process.env.REACT_APP_AES_SALT
    const prompt_access_token = decryptData(localStorage.getItem('prompt_access_token', null), aes_salt_key)

    const [isLoading, setIsLoading] = useState(true)
    const [showSuccessAlert, setShowSuccessAlert] = useState(false)
    const [showFailAlert, setShowFailAlert] = useState(false)
    const [deleteChats, setDeleteChats] = useState(false)

    const [initial, setInitial] = useState('')
    const [havent, setHavent] = useState('')
    const [first, setFirst] = useState('')
    const [followup, setFollowup] = useState('')
    const [followupWelcome, setFollowupWelcome] = useState('')
    const [welcome24, setWelcome24] = useState('')
    const [force, setForce] = useState('')




    const get_prompt = async () => {
        console.log("getting prompt :", API_URL)
        const res = await fetch(`${API_URL}/api/v1/prompt`, {
            method: 'GET',
            headers: {
                'Authorization':`Bearer ${prompt_access_token}`
            }
        })

        const res_status = res.status
        const data = await res.json()
        console.log('prompt :', data)

        setIsLoading(false)
        if(res_status === 200) {
            const prompt_data = data['prompt']
            setInitial(prompt_data['initial'])
            setHavent(prompt_data['havent'])
            setFirst(prompt_data['first'])
            setFollowup(prompt_data['followup'])
            setFollowupWelcome(prompt_data['followup_welcome'])
            setWelcome24(prompt_data['welcome_24'])
            setForce(prompt_data['force'])
        }else{
            console.log("Err in get initial prompt data:", res_status, data)
        }
    }


    useEffect(()=>{
        get_prompt()
    }, [])


    const handle_save_prompt = async () => {
        // console.log("new initial :", initial)
        // console.log("new havent :", havent)
        // console.log("new first :", first)
        // console.log("new followup :", followup)
        // console.log("new followup welcome :", followupWelcome)
        // console.log("new welcome 24 :", welcome24)
        // console.log("new force :", force)
        var prompt = {
            "initial" : initial,
            "havent" : havent,
            "first" : first,
            "followup" : followup,
            "followup_welcome" : followupWelcome,
            "welcome_24" : welcome24,
            "force" : force
        }
        
        setIsLoading(true)
        const res = await fetch(`${API_URL}/api/v1/prompt`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':`Bearer ${prompt_access_token}`,
            },
            body: JSON.stringify({'prompt':prompt})
        })

        const res_status = res.status
        const data = await res.json()
        console.log('returned prompt after update :', data['prompt'])

        setIsLoading(false)
        if(res_status === 200) {
            const prompt_data = data['prompt']
            setInitial(prompt_data['initial'])
            setHavent(prompt_data['havent'])
            setFirst(prompt_data['first'])
            setFollowup(prompt_data['followup'])
            setFollowupWelcome(prompt_data['followup_welcome'])
            setWelcome24(prompt_data['welcome_24'])
            setForce(prompt_data['force'])

            setShowSuccessAlert(true)
            TimeOutHandler(()=>{
                setShowSuccessAlert(false)
            }, 5000)

        }else{
            console.log("failed to update prompt", res_status, data)
            setShowFailAlert(true)
            TimeOutHandler(()=>{
                setShowFailAlert(false)
            }, 5000)

        }
    }


    const handle_delete_chats = async () => {
        console.log("Handle delete chats")
        setDeleteChats(true)

        setIsLoading(true)
        const res = await fetch(`${API_URL}/api/v1/chats`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':`Bearer ${prompt_access_token}`,
            },
        })

        const res_status = res.status
        const data = await res.json()
        console.log('delete chats res :', data)

        setIsLoading(false)
        if(res_status === 200) {
            setShowSuccessAlert(true)
            TimeOutHandler(()=>{
                setShowSuccessAlert(false)
                setDeleteChats(false)
            }, 5000)

        }else{
            console.log("failed to delete chats", res_status, data)
            setShowFailAlert(true)
            TimeOutHandler(()=>{
                setShowFailAlert(false)
                setDeleteChats(false)
            }, 5000)
        }


    }



    const handleGoToTop = () => {
        elementRef.current.scrollIntoView({behavior: 'smooth'});
    }


    return (
        <div className={main_classes.full_screen_center_center}>
            {width > 500 && <NavBar/>}
            <div className={classes.edit_div}>
                {isLoading?(
                        <GooSpinner/>
                ):(
                    <div className={classes.edit_div_form}>
                        <div className={classes.edit_div_heading} ref={elementRef}>
                            <div className={classes.edit_div_heading_left}>
                                <div className={classes.edit_div_heading_left_top}>
                                    <h1>Edit Prompt</h1>
                                    {showSuccessAlert && <p className={classes.green_text}>{deleteChats?"(Chats Deleted Successfully)":"(Operation Successful)"}</p>}
                                    {showFailAlert && <p className={classes.red_text}>{deleteChats?"(Chat Deletion Failed)":"(Operation Failed)"}</p>}
                                </div>
                                <p>Prompt texts for v4.0 astrology app (user first) can be edited from here</p>
                            </div>
                            <div className={classes.edit_div_heading_right}>
                                <button onClick={handle_save_prompt}>✔ Save</button>
                                <p>♦︎</p>
                                <button onClick={handle_delete_chats} className={classes.btn_red}>⏺ Delete Chats</button>
                            </div>
                        </div>

                        <div className={classes.each_input_div}>
                            <div className={classes.each_input_div_each}>
                                <div className={classes.label_div}>
                                    <p>Initial Prompt</p>
                                    <small>(When user first sends a message to agent)</small>
                                </div>

                                <div className={classes.input_div}>
                                    <textarea onChange={(e) => setInitial(e.target.value)} value={initial}></textarea>
                                </div>
                            </div>
                        </div>
                        <div className={classes.each_input_div}>
                            <div className={classes.each_input_div_each}>
                                <div className={classes.label_div}>
                                    <p>Havent Hear From You Prompt</p>
                                    <small>(When user never sent any msg and reloads the app)</small>
                                </div>
                                <div className={classes.input_div}>
                                    <textarea onChange={(e) => setHavent(e.target.value)} value={havent}></textarea>
                                </div>
                            </div>
                        </div>
                        <div className={classes.each_input_div}>
                            <div className={classes.each_input_div_each}>
                                <div className={classes.label_div}>
                                    <p>First Followup Prompt</p>
                                    <small>(Used once after user's first msg to check if user sent his name and location)</small>
                                </div>
                                <div className={classes.input_div}>
                                    <textarea onChange={(e) => setFirst(e.target.value)} value={first}></textarea>
                                </div>
                            </div>
                        </div>
                        <div className={classes.each_input_div}>
                            <div className={classes.each_input_div_each}>
                                <div className={classes.label_div}>
                                    <p>Followup Prompt</p>
                                    <small>(Process user's msg and reply based on previous chatting)</small>
                                </div>
                                <div className={classes.input_div}>
                                    <textarea onChange={(e) => setFollowup(e.target.value)} value={followup}></textarea>
                                </div>
                            </div>
                        </div>
                        <div className={classes.each_input_div}>
                            <div className={classes.each_input_div_each}>
                                <div className={classes.label_div}>
                                    <p>Followup Welcome Prompt</p>
                                    <small>(User had chats and returned back to app again after 24 hours)</small>
                                </div>
                                <div className={classes.input_div}>
                                    <textarea onChange={(e) => setFollowupWelcome(e.target.value)} value={followupWelcome}></textarea>
                                </div>
                            </div>
                        </div>
                        <div className={classes.each_input_div}>
                            <div className={classes.each_input_div_each}>
                                <div className={classes.label_div}>
                                    <p>Welcome Prompt After 24h</p>
                                    <small>(When user never sent any msg but came back to app after 24 hours)</small>
                                </div>
                                <div className={classes.input_div}>
                                    <textarea onChange={(e) => setWelcome24(e.target.value)} value={welcome24}></textarea>
                                </div>
                            </div>
                        </div>
                        <div className={classes.each_input_div}>
                            <div className={classes.each_input_div_each}>
                                <div className={classes.label_div}>
                                    <p>Force Prompt</p>
                                    <small>(User kept chatting so force him to jojo.co.il)</small>
                                </div>
                                <div className={classes.input_div}>
                                    <textarea onChange={(e) => setForce(e.target.value)} value={force}></textarea>
                                </div>
                            </div>
                        </div>

                        <button className={classes.go_to_top_btn} onClick={handleGoToTop}>↑ Go To Top 
                        {/* <ScrollToTop smooth /> */}
                        </button>
                    </div>
                )}
                
            </div>
        </div>
    )
}

export default EditPrompt;