import './App.css';
// import LoginLoad from './components/LoginLoad/LoginLoad';

import {Route, Routes, Navigate} from 'react-router-dom'

// import {useEffect, useState, useRef} from 'react'
// import { useNavigate } from "react-router-dom";
// import { useSelector, useDispatch } from 'react-redux';

// import { userActions } from './store/user-slice';
// import { uiActions } from './store/ui-slice';
// import { TimeOutHandler } from './utils/helper_functions';

import Main from './pages/Main/Main'
import EditPrompt from './pages/Edit/EditPrompt'



function App() {
  // const dispatch = useDispatch()
  // const navigate = useNavigate()
  // const initPath = useRef(window.location.pathname)

  // const API_URL = process.env.REACT_APP_API_URL
  // const PATH_NAME = window.location.pathname

  // states
  // const [loginProcess, setLoginProcess] = useState(false)


  return (  
    <div className="app">     
      <Routes>
        <Route path='/' exact element={<Main/>}/>
        <Route path='/agent/:agent_name/:chatroom_key' exact element={<Main/>}/>
        <Route path='/edit' exact element={<EditPrompt/>}/>
        <Route path='/*' exact element={<Navigate to='/'/> }/>
      </Routes>
    </div>
  );
}

export default App;
